import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2e8ab0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action desktop-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/select-product"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Schedule new threshold")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("aside", null, [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Info")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.shirtOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.products.total.variant) + " " + _toDisplayString(_ctx.$t('SKUs')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.optionsOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.threshold) + " " + _toDisplayString(_ctx.$t('threshold')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { color: "medium" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Name")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        placeholder: _ctx.$t('rule name'),
                        modelValue: _ctx.jobName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.jobName) = $event))
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.timeOutline
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Run time")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, {
                        onClick: _cache[1] || (_cache[1] = () => _ctx.isOpen = true),
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.initialRunTime ? _ctx.getTime(_ctx.initialRunTime) : _ctx.$t('Select run time')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_modal, {
                        "is-open": _ctx.isOpen,
                        onDidDismiss: _cache[3] || (_cache[3] = () => _ctx.isOpen = false)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_component_ion_datetime, {
                                "show-default-buttons": "",
                                "hour-cycle": "h12",
                                key: _ctx.DateTime.now().toMillis(),
                                value: _ctx.initialRunTime ? _ctx.getDateTime(_ctx.initialRunTime) : '',
                                onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateRunTime($event)))
                              }, null, 8, ["value"]))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["is-open"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("main", null, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("Threshold pipeline")), 1),
              _createVNode(_component_ion_reorder_group, {
                onIonItemReorder: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doReorder($event))),
                disabled: "false"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobsForReorder, (job) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: job.jobId
                    }, [
                      _createVNode(_component_ion_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(job.jobId ? job.jobName : _ctx.jobName), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_reorder)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getEnumDescription(job.systemJobEnumId) ? _ctx.getEnumDescription(job.systemJobEnumId) : job.systemJobEnumId), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (_ctx.successJobs.includes(job.jobId))
                                ? (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 0,
                                    icon: _ctx.checkmarkCircleOutline,
                                    color: "success"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              (_ctx.failedJobs.includes(job.jobId))
                                ? (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 1,
                                    icon: _ctx.closeCircleOutline,
                                    color: "danger"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                icon: _ctx.timeOutline
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(job.runTime ? _ctx.getTime(job.runTime) : "-"), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (job.runTime)
                                ? (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 0,
                                    color: "dark"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.timeTillJob(job.runTime)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                icon: _ctx.timerOutline
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(job.tempExprId && _ctx.temporalExpr(job.tempExprId)?.description ? _ctx.temporalExpr(job.tempExprId)?.description : "🙃"), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_button, {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveThresholdRule())),
              disabled: _ctx.failedJobs.length || _ctx.isServiceScheduling
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.saveOutline
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Schedule Job")), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed",
            class: "mobile-only"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saveThresholdRule())),
                disabled: _ctx.failedJobs.length || _ctx.isServiceScheduling
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.arrowForwardOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}