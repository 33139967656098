
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  checkmarkDoneOutline,
  downloadOutline,
  filterOutline
} from "ionicons/icons";
import { useRouter } from "vue-router";
import SafetyStockModal from "@/components/SafetyStockModal.vue";

export default defineComponent({
  name: "SelectFacility",
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonCheckbox,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
  },
  methods: {
    async setSafetyStock() {
      const safetystockmodal = await modalController.create({
        component: SafetyStockModal,
      });
      return safetystockmodal.present();
    },
  },
  setup() {
    const router = useRouter();
    return {
      checkmarkDoneOutline,
      downloadOutline,
      filterOutline,
      router
    };
  },
});
