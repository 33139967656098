
import { mapGetters, useStore } from 'vuex'
import { defineComponent, ref } from "vue";
import {
  IonBadge,
  IonButton,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonTitle,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  alertController,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  isPlatform,
  modalController,
  createAnimation
} from "@ionic/vue";
import JobConfiguration from '@/components/JobConfiguration.vue'
import { copyOutline, closeCircleOutline, checkmarkCircleOutline, pencilOutline, optionsOutline, timeOutline, timerOutline } from "ionicons/icons";
import { Plugins } from '@capacitor/core';
import { hasError, showToast } from '@/utils'
import JobHistoryModal from '@/components/JobHistoryModal.vue';
import { DateTime } from 'luxon';
import { ProductService } from '@/services/ProductService';
import { Actions, hasPermission } from '@/authorization'
import JobReorderModal from '@/components/JobReorderModal.vue';
import emitter from '@/event-bus';

export default defineComponent({
  name: "ThresholdUpdates",
  components: {
    IonBadge,
    IonButton,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    IonTitle,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    JobConfiguration
  },
  data() {
    return {
      jobFrequencyType: JSON.parse(process.env?.VUE_APP_JOB_FREQUENCY_TYPE as string) as any,
      jobEnums: [
        ...JSON.parse(process.env?.VUE_APP_JOB_ENUMS as string) as any
      ],
      currentJob: '' as any,
      title: '',
      currentJobStatus: '',
      freqType: '' as any,
      isJobDetailAnimationCompleted: false,
      isDesktop: isPlatform('desktop'),
      isRetrying: false,
      productCount: 0
    }
  },
  computed: {
    ...mapGetters({
      jobHistory: 'job/getJobHistory',
      pendingJobs: 'job/getPendingJobs',
      runningJobs: 'job/getRunningJobs',
      temporalExpr: 'job/getTemporalExpr',
      getTags: 'job/getTags',
      getEnumDescription: 'job/getEnumDescription',
      getEnumName: 'job/getEnumName',
      isPendingJobsScrollable: 'job/isPendingJobsScrollable',
      isRunningJobsScrollable: 'job/isRunningJobsScrollable',
      isHistoryJobsScrollable: 'job/isHistoryJobsScrollable',
      products: 'product/getProducts',
      query: 'job/getThresholdRule'
    })
  },
  mounted(){
    this.store.dispatch('util/getServiceStatusDesc')
    emitter.on("productStoreChanged", this.refreshJobs);
  },
  unmounted() {
    emitter.off("productStoreChanged", this.refreshJobs);
  },
  methods: {
    getJobExecutionTime(startTime: any, endTime: any){
      if (startTime && endTime) {
        const timeDiff = DateTime.fromMillis(endTime).diff( DateTime.fromMillis(startTime))
        const hours =  timeDiff.hours
        const minutes = timeDiff.minutes
        const seconds =  timeDiff
        let format = ""
        if(hours) format += "hh 'hr' "
        if(minutes) format += "mm 'min' "
        if(seconds) format += "ss 'sec'"
        if (format) return timeDiff.toFormat(format);
      }
      return
    },
    async copyJobInformation(job: any) {
      const { Clipboard } = Plugins;
      const jobDetails = `jobId: ${job.jobId}, jobName: ${this.getEnumName(job.systemJobEnumId)}, jobDescription: ${this.getEnumDescription(job.systemJobEnumId)}`;

      await Clipboard.write({
        string: jobDetails
      }).then(() => {
        showToast(this.$t("Copied job details to clipboard"));
      })
    },
    async viewJobHistory(job: any) {
      const jobHistoryModal = await modalController.create({
        component: JobHistoryModal,
        componentProps: { currentJob: job }
      });
      return jobHistoryModal.present();
    },
    getTime (time: any) {
      return DateTime.fromMillis(time).toLocaleString(DateTime.TIME_SIMPLE);
    },
    timeFromNow (time: any) {
      const timeDiff = DateTime.fromMillis(time).diff(DateTime.local());
      return DateTime.local().plus(timeDiff).toRelative();
    },
    async loadMoreJobHistory(event: any){
      this.getJobHistory(
        undefined,
        Math.ceil(this.jobHistory.length / (process.env.VUE_APP_VIEW_SIZE as any)).toString()
      ).then(() => {
        event.target.complete();
      })
    },
    async loadMoreRunningJobs(event: any){
      this.getRunningJobs(
        undefined,
        Math.ceil(this.runningJobs.length / (process.env.VUE_APP_VIEW_SIZE as any)).toString()
      ).then(() => {
        event.target.complete();
      })
    },
    async loadMorePendingJobs (event: any) {
      this.getPendingJobs(
        undefined,
        Math.ceil(this.pendingJobs.length / (process.env.VUE_APP_VIEW_SIZE as any)).toString()
      ).then(() => {
        event.target.complete();
      })
    },
    async refreshJobs(event: any, isRetrying = false) {
      this.isRetrying = isRetrying;
      if(this.segmentSelected === 'pending') {
        this.store.dispatch('job/fetchPendingJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums}).then(() => {
          if(event) event.target.complete();
          this.isRetrying = false;
        });
      } else if(this.segmentSelected === 'running') {
        this.store.dispatch('job/fetchRunningJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums}).then(() => {
          if(event) event.target.complete();
          this.isRetrying = false;
        });
      } else {
        this.store.dispatch('job/fetchJobHistory', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums}).then(() => {
          if(event) event.target.complete();
          this.isRetrying = false;
        });
      }
    },
    segmentChanged (e: CustomEvent) {
      this.segmentSelected = e.detail.value
      this.segmentSelected === 'pending' ? this.store.dispatch('job/fetchPendingJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums}) : 
      this.segmentSelected === 'running' ? this.store.dispatch('job/fetchRunningJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums}) :
      this.store.dispatch('job/fetchJobHistory', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums});
    },
    async skipJob (job: any) {
      const alert = await alertController
        .create({
          header: this.$t('Skip job'),
          message: this.$t('Skipping will run this job at the next occurrence based on the temporal expression.'),
          buttons: [
            {
              text: this.$t("Don't skip"),
              role: 'cancel',
            },
            {
              text: this.$t('Skip'),
              handler: async () => {
                await this.store.dispatch('job/skipJob', job);
                await this.store.dispatch('job/fetchPendingJobs', {viewIndex: 0, jobEnums: this.jobEnums})
              },
            }
          ]
        });
      return alert.present();
    },
    async getPendingJobs(vSize: any, vIndex: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;
      await this.store.dispatch('job/fetchPendingJobs', {viewSize, viewIndex, jobEnums: this.jobEnums});
    },
    async getRunningJobs(vSize: any, vIndex: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;
      await this.store.dispatch('job/fetchRunningJobs', {viewSize, viewIndex, jobEnums: this.jobEnums});
    },
    async getJobHistory(vSize: any, vIndex: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;
      await this.store.dispatch('job/fetchJobHistory', {viewSize, viewIndex, jobEnums: this.jobEnums});
    },
    async cancelJob(job: any){
      const alert = await alertController
        .create({
          header: this.$t('Cancel job'),
          message: this.$t('Canceling this job will cancel this occurrence and all following occurrences. This job will have to be re-enabled manually to run it again.'),
          buttons: [
            {
              text: this.$t("DON'T CANCEL"),
              role: 'cancel',
            },
            {
              text: this.$t("CANCEL"),
              handler: async () => {
                const resp = await this.store.dispatch('job/cancelJob', job);
                if(resp.status == 200 && !hasError(resp) && resp.data.successMessage) {
                  this.store.dispatch('job/fetchPendingJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums})
                }
              },
            }
          ],
        });

       return alert.present();
    },    
    viewJobConfiguration(job: any) {
      if(!this.isDesktop) {
        return;
      }
      // For Jobs like Import jobs, we will not have any rule setup
      if (job.runtimeData?.searchPreferenceId) {
        const query = JSON.parse(JSON.stringify(this.query(job.runtimeData.searchPreferenceId)))
        this.getProductCount(query);
      }
      this.currentJob = {id: job.jobId, ...job}
      this.title = this.getEnumName(job.systemJobEnumId)
      this.currentJobStatus = job.tempExprId
      const id = this.jobEnums.find((enums) => enums === job.systemJobEnumId)
      this.freqType = id && (Object.entries(this.jobFrequencyType).find((freq) => freq[0] == id) as any)[1]
      if (this.currentJob && !this.isJobDetailAnimationCompleted) {
        this.playAnimation();
        this.isJobDetailAnimationCompleted = true;
      }
    },
    async getProductCount(query: any){
      //Passed rows = 0 as we only need product count and not the data
      query.json.params.rows = 0;
      try {
        const resp = await ProductService.getProducts(query);
        if(resp.status === 200 && !hasError(resp) && resp.data.response){
          this.productCount = resp.data.response.numFound
        } else {
          this.$log.error(resp);
          this.productCount = 0;
        } 
      } catch (err) {
        this.$log.error(err);
        this.productCount = 0;
      }
    },
    playAnimation() {
      const aside = this.$el.querySelector('aside') as Element
      const main = document.querySelector('main') as Element

      const revealAnimation = createAnimation()
        .addElement(aside)
        .duration(1500)
        .easing('ease')
        .keyframes([
          { offset: 0, flex: '0', opacity: '0' },
          { offset: 0.5, flex: '1', opacity: '0' },
          { offset: 1, flex: '1', opacity: '1' }
        ])

      const gapAnimation = createAnimation()
        .addElement(main)
        .duration(500)
        .fromTo('gap', '0', 'var(--spacer-2xl)');

      createAnimation()
        .addAnimation([gapAnimation, revealAnimation])
        .play();
    },
    async openReorderModal() {
      const jobReorderModal = await modalController.create({
        component: JobReorderModal,
        componentProps: { jobs: this.pendingJobs.filter((job: any) => job.systemJobEnumId === 'JOB_EXP_PROD_THRSHLD')} // passing export jobs, as we will only reorder export threshold jobs
      })
      jobReorderModal.onDidDismiss().then((result: any) => {
        if (result?.data?.isJobsUpdated) {
          this.store.dispatch('job/fetchPendingJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums});
        }
      })
      return jobReorderModal.present();
    }
  },
  ionViewWillEnter() {
    // reassigning current job when entering in the view to not display the job config component if previously opened
    this.currentJob = undefined
    this.isJobDetailAnimationCompleted = false
    if (this.segmentSelected === 'pending') {
      this.store.dispatch('job/fetchPendingJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums})
    } else if (this.segmentSelected === 'running') {
      this.store.dispatch('job/fetchRunningJobs', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums})
    } else {
      this.store.dispatch('job/fetchJobHistory', {viewSize:process.env.VUE_APP_VIEW_SIZE, viewIndex:0, jobEnums: this.jobEnums});
    }
  },
  setup() {
    const store = useStore();
    const segmentSelected = ref('pending');

    return {
      Actions,
      copyOutline,
      hasPermission,
      store,
      closeCircleOutline,
      checkmarkCircleOutline,
      pencilOutline,
      optionsOutline,
      timeOutline,
      timerOutline,
      segmentSelected
    };
  }
});
