
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { arrowBackOutline, checkmarkOutline } from 'ionicons/icons'
import { ProductService } from "@/services/ProductService";
import { mapGetters, useStore } from "vuex";

export default defineComponent({
  name: "ProductFilterModal",
  components: {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonSearchbar,
    IonTitle,
    IonToolbar
  },
  data() {
    return {
      queryString: '',
      facetOptions: [] as any,
      isFilterChanged: false,
      isScrollable: true,
      selectedValues: [] as Array<string>
    }
  },
  computed: {
    ...mapGetters({
      appliedFilters: 'product/getAppliedFilters'
    })
  },
  props: ["label", "facetToSelect", "searchfield", 'type'],
  mounted() {
    this.selectedValues = JSON.parse(JSON.stringify(this.appliedFilters[this.type][this.searchfield])).list;
  },
  methods: {
    closeModal() {
      modalController.dismiss({ dismissed: true, isFilterChanged: this.isFilterChanged });
    },
    async search(event: any) {
      this.queryString = event.target.value;
      this.getTags();
    },
    async getTags(vSize?: any, vIndex?: any) {
      const viewSize = vSize ? vSize : process.env.VUE_APP_VIEW_SIZE;
      const viewIndex = vIndex ? vIndex : 0;

      const payload = {
        facetToSelect: this.facetToSelect,
        docType: 'PRODUCT',
        coreName: 'enterpriseSearch',
        searchfield: this.searchfield,
        jsonQuery: '{"query":"*:*","filter":["docType:PRODUCT"]}',
        noConditionFind: 'N',
        limit: viewSize,
        q: this.queryString,
        term: this.queryString,
        offset: viewIndex * viewSize,
      }

      const resp = await ProductService.fetchFacets(payload);
      if (resp.status == 200 && resp.data.length > 0) {
        this.facetOptions = viewIndex === 0 ? resp.data : [...this.facetOptions , ...resp.data];
        this.isScrollable = (this.facetOptions.length % process.env.VUE_APP_VIEW_SIZE) === 0;
      } else {
        this.facetOptions = [];
        this.isScrollable = false;
      }
    },
    async loadMoreTags(event: any){
      this.getTags(
        undefined,
        Math.ceil(this.facetOptions.length / process.env.VUE_APP_VIEW_SIZE).toString() 
      ).then(() => {
        event.target.complete();
      })
    },
    updateSelectedValues(value: string) {
      this.selectedValues.includes(value) ? this.selectedValues.splice(this.selectedValues.indexOf(value), 1) : this.selectedValues.push(value);
    },
    async updateFilters() {
      this.isFilterChanged = true;
      this.selectedValues.length ? await this.updateAppliedFilters() : await this.clearFilters();
      this.closeModal();
    },
    async updateAppliedFilters() {
      // if filters are not updated
      if (JSON.stringify(this.appliedFilters[this.type][this.searchfield].list) == JSON.stringify(this.selectedValues)) {
        this.isFilterChanged = false;
        return;
      }

      const appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters[this.type][this.searchfield]))
      appliedFilters.list = this.selectedValues
      
      await this.store.dispatch('product/updateAppliedFilters', {
        type: this.type,
        id: this.searchfield,
        value: appliedFilters
      })
    },
    async clearFilters() {
      // if no filters are already not applied
      if (!this.appliedFilters[this.type][this.searchfield].list.length) {
        this.isFilterChanged = false;
        return;
      }

      await this.store.dispatch('product/clearFilters', {
        type: this.type,
        id: this.searchfield,
        value: {
          list: [],
          operator: 'OR'
        }
      })
    },
    isAlreadyApplied(value: string) {
      const type = this.type === 'included' ? 'excluded' : 'included'
      return this.appliedFilters[type][this.searchfield].list.includes(value)
    }
  },
  setup() {
    const store = useStore();

    return {
      arrowBackOutline,
      checkmarkOutline,
      store
    }
  }
})
