
import { defineComponent } from 'vue';
import {
  IonContent,
} from '@ionic/vue';

export default defineComponent ({
  name: 'ErrorMessageModal',
  components: {
    IonContent,
  },
  props: ["errorMessage"]
})
