
  import {
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonNote,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    menuController
  } from "@ionic/vue";
  import { defineComponent } from "vue";
  import { mapGetters } from "vuex";
  import { useStore } from "@/store";
  import { hasPermission } from "@/authorization";
  import { useRouter } from "vue-router";
  import { optionsOutline, settingsOutline, pulseOutline } from 'ionicons/icons';
  import emitter from "@/event-bus";
  
  export default defineComponent({
    name: "Menu",
    components: {
      IonContent,
      IonFooter,
      IonHeader,
      IonIcon,
      IonItem,
      IonLabel,
      IonList,
      IonMenu,
      IonNote,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar
    },
    computed: {
      ...mapGetters({
        isUserAuthenticated: 'user/isUserAuthenticated',
        eComStore: 'user/getCurrentEComStore',
        instanceUrl: 'user/getInstanceUrl',
        userProfile: 'user/getUserProfile',
      })
    },
    methods: {
      async closeMenu() {
       await menuController.close();
      },
      async setEComStore(event: CustomEvent) {
        if(this.eComStore.productStoreId !== event.detail.value) {
          await this.store.dispatch('user/setEcomStore', { 'productStoreId': event.detail.value })
          emitter.emit("productStoreChanged")
        }
      }
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      return {
        router,
        pulseOutline,
        hasPermission,
        optionsOutline,
        settingsOutline,
        store
      };
    },
  });
  