
import {
  IonButtons,
  IonButton,
  IonBadge,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';
import { mapGetters, useStore } from 'vuex';
import { DateTime } from 'luxon';
import { JobService } from '@/services/JobService'
import { hasError } from '@/utils';

export default defineComponent({
  name: 'JobHistoryModal',
  components: {
    IonButtons,
    IonButton,
    IonBadge,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {
      jobHistory: []
    }
  },
  props: ['currentJob'],
  computed: {
    ...mapGetters({
      getEnumName: 'job/getEnumName',
      getCurrentEComStore:'user/getCurrentEComStore',
      getStatusDesc: 'util/getStatusDesc'
    })
  },
  methods: {
    closeModal() {
      modalController.dismiss({ dismissed: true });
    },
    getDate (runTime: any) {
      return DateTime.fromMillis(runTime).toLocaleString(DateTime.DATE_MED);
    },
    getTime (runTime: any) {
      return DateTime.fromMillis(runTime).toLocaleString(DateTime.TIME_SIMPLE);
    },
    async fetchJobHistory() {
      let resp;

      try {
        resp = await JobService.fetchJobInformation({
          "inputFields": {
            "productStoreId": this.getCurrentEComStore.productStoreId,
            "statusId": ["SERVICE_CANCELLED", "SERVICE_CRASHED", "SERVICE_FAILED", "SERVICE_FINISHED"],
            "statusId_op": "in",
            "systemJobEnumId": this.currentJob?.systemJobEnumId
          },
          "fieldList": [ "runTime", "statusId" ],
          "entityName": "JobSandbox",
          "noConditionFind": "Y",
          "viewSize": process.env.VUE_APP_VIEW_SIZE,
          "orderBy": "runTime DESC"
        })
        if(resp.status === 200 && resp.data.docs?.length > 0 && !hasError(resp)) {
          this.jobHistory = resp.data.docs;
        } else {
          this.jobHistory = [];
        }
      } catch(err) {
        this.$log.error(err);
      }
    }
  },
  mounted() {
    this.fetchJobHistory()
  },
  setup() {
    const store = useStore();

    return {
      closeOutline,
      store
    };
  },
});
