import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d83317fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions desktop-only" }
const _hoisted_2 = { class: "actions mobile-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ion_item, { lines: "none" }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_ctx.job?.runTime)
          ? (_openBlock(), _createBlock(_component_ion_badge, {
              key: 0,
              slot: "end",
              color: "dark"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("running")) + " " + _toDisplayString(_ctx.timeTillJob(_ctx.runTime ? _ctx.runTime : _ctx.job.runTime)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.calendarClearOutline
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Last run")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, { slot: "end" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.job?.lastUpdatedStamp ? _ctx.getTime(_ctx.job.lastUpdatedStamp) : _ctx.$t('No previous occurrence')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.timeOutline
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Run time")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, {
              onClick: _cache[0] || (_cache[0] = () => _ctx.isOpen = true),
              slot: "end"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.job?.runTime ? _ctx.getTime(_ctx.runTime ? _ctx.runTime : _ctx.job.runTime) : _ctx.$t('Select run time')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              "is-open": _ctx.isOpen,
              onDidDismiss: _cache[2] || (_cache[2] = () => _ctx.isOpen = false)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_datetime, {
                      "show-default-buttons": "",
                      "hour-cycle": "h12",
                      value: _ctx.job?.runTime ? _ctx.getDateTime(_ctx.job.runTime) : '',
                      onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateRunTime($event, _ctx.job)))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["is-open"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, { lines: "inset" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.timerOutline
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Schedule")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_select, {
              "interface-options": _ctx.customPopoverOptions,
              interface: "popover",
              value: _ctx.jobStatus,
              placeholder: _ctx.$t('Disabled'),
              onIonChange: _cache[3] || (_cache[3] = ($event) => _ctx.jobStatus = $event['detail'].value)
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateFrequencyOptions, (freq) => {
                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                    key: freq.value,
                    value: freq.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(freq.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["interface-options", "value", "placeholder"])
          ]),
          _: 1
        }),
        (_ctx.job?.systemJobEnumId === 'JOB_EXP_PROD_THRSHLD')
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 0,
              lines: "inset"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.cogOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Name")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  class: "ion-text-end",
                  name: "ruleName",
                  modelValue: _ctx.ruleName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ruleName) = $event)),
                  id: "ruleName"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.job?.runtimeData?.searchPreferenceId)
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 1,
              button: "",
              detail: "true",
              onClick: _ctx.updateThresholdRule,
              lines: "full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.pencilOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Edit threshold rule")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_note, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.productCount) + " " + _toDisplayString(_ctx.$t("products selected")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          color: "medium",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT',
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.skipJob(_ctx.job)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Skip once")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          color: "danger",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT',
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancelJob(_ctx.job)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Disable")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveChanges()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Save changes")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_button, {
        size: "small",
        fill: "outline",
        color: "medium",
        disabled: _ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT',
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.skipJob(_ctx.job)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Skip once")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_ion_button, {
        size: "small",
        fill: "outline",
        color: "danger",
        disabled: _ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT',
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.cancelJob(_ctx.job)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Disable")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_ion_button, {
        expand: "block",
        fill: "outline",
        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveChanges()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Save changes")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}