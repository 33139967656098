import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.arrowBackOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`${ _ctx.type === 'included' ? `Include ${_ctx.label}` : `Exclude ${_ctx.label}` }`)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  fill: "clear",
                  color: "danger",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedValues = []))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Clear All")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          placeholder: _ctx.$t(`Search ${_ctx.label}`),
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryString) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.search($event)}, ["enter"]))
        }, null, 8, ["placeholder", "modelValue"]),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facetOptions, (option) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: option.id,
                onClick: ($event: any) => (_ctx.updateSelectedValues(option.id))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (!_ctx.isAlreadyApplied(option.id))
                    ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                        key: 0,
                        checked: _ctx.selectedValues.includes(option.id)
                      }, null, 8, ["checked"]))
                    : (_openBlock(), _createBlock(_component_ion_note, {
                        key: 1,
                        slot: "end",
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.type === 'included' ? _ctx.$t("excluded") : _ctx.$t("included")), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_ion_fab, {
          class: "ion-padding",
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateFilters()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_infinite_scroll, {
          onIonInfinite: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadMoreTags($event))),
          threshold: "100px",
          disabled: !_ctx.isScrollable
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_infinite_scroll_content, {
              "loading-spinner": "crescent",
              "loading-text": _ctx.$t('Loading')
            }, null, 8, ["loading-text"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}