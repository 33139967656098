import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Upload CSV")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Product list")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.$t('Select CSV')
                }, null, 8, ["placeholder"]),
                _createVNode(_component_ion_button, { fill: "outline" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Upload")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Select the column index for the following information in the uploaded CSV")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Product SKU column")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  placeholder: _ctx.$t('Select')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, null, {
                      default: _withCtx(() => [
                        _createTextVNode("1234")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_select_option, null, {
                      default: _withCtx(() => [
                        _createTextVNode("5678")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_select_option, null, {
                      default: _withCtx(() => [
                        _createTextVNode("9012")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              expand: "block",
              size: "large",
              color: "medium"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Save")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("50 " + _toDisplayString(_ctx.$t("products selected")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  slot: "end",
                  fill: "outline",
                  color: "dark"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.downloadOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("Download")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}