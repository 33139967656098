import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfae1b74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    side: "start",
    "menu-id": "first",
    "content-id": "main",
    type: "overlay",
    disabled: !_ctx.isUserAuthenticated
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Menu")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_ctx.hasPermission('APP_SELECT_PRODUCT_VIEW'))
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    button: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.closeMenu(); _ctx.router.push('/select-product')})
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.optionsOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Create Rule")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.hasPermission('APP_THRESHOLD_UPDATES_VIEW'))
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 1,
                    button: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.closeMenu(); _ctx.router.push('/threshold-updates')})
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.pulseOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Rule Pipeline")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.closeMenu(); _ctx.router.push('/settings')})
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.settingsOutline,
                    slot: "start"
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.instanceUrl), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.userProfile?.userTimeZone), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.userProfile?.stores?.length > 1)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        value: _ctx.eComStore.productStoreId,
                        onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setEComStore($event)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.userProfile?.stores ? _ctx.userProfile.stores : []), (store) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: store.productStoreId,
                              value: store.productStoreId
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(store.storeName), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_item, {
                    key: 1,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.eComStore.storeName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}